<template>
  <section class="ts-form-hero">
    <div class="ts-bg-gradient">
      <div class="ts-bg-1 ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
      <div class="ts-bg-3 ts-bg-radius ts-bg-radius--secondary z-minus-1"></div>
    </div>
    <div
      class="ts-bg-2 ts-bg-radius ts-bg-radius--primary-secondary--mix z-minus-1"
    ></div>
    <div class="ts-container-fluid container-fluid">
      <div class="ts-form-hero__content mx-auto">
        <img
          class="ts-hash"
          src="/images/hash-white.svg"
          alt="alternativeText"
        />
        <div v-show="elementVisible" class="ts-image-container">
          <div class="ts-image ts-image__1">
            <img src="/images/signup/burgur.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__2">
            <img src="/images/signup/donut.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__3">
            <div class="ts-inner-img">
              <img src="/images/signup/emoji2.png" alt="alternativeText" />
            </div>
          </div>
          <div class="ts-image ts-image__4">
            <img src="/images/signup/emoji.png" alt="alternativeText" />
          </div>
          <div class="ts-image ts-image__5">
            <div class="ts-rotate">
              <img src="/images/Home/heart.png" alt="alternativeText" />
            </div>
          </div>
        </div>
        <div class="row row-cols-lg-2 align-items-center gap-5 gap-lg-0">
          <div class="order-1 order-lg-0">
            <header
              class="ts-creator-form-hero__header ts-text-gray-2"
              v-if="$store.state.creatorSignupPage"
            >
              <img
                height="43"
                class="mb-03"
                :src="
                  $store.state.baseUrl +
                  '/uploads/quote_close_svgrepo_com_4c34c2e02c.svg'
                "
                alt="alternativeText"
              />
              <h1 class="ts-fs-2-b fw-bold mb-04 mb-lg-07">
                {{ $store.state.creatorSignupPage.mainDetails.title }}
              </h1>
              <p class="ts-fs-5 fw-light mb-05 mb-lg-07">
                {{ $store.state.creatorSignupPage.mainDetails.description }}
              </p>

              <div
                class="ts-text-gray-1 d-flex ts-rtl-justify-content-end ts-rtl-row-reverse align-items-center gap-3"
              >
                <div>
                  <img
                    width="70"
                    height="70"
                    class="rounded-circle"
                    :src="
                      $store.state.baseUrl +
                      $store.state.creatorSignupPage.mainDetails
                        .TestimonialContact.Image.data.attributes.url
                    "
                    alt="alternativeText"
                  />
                </div>
                <div>
                  <h5 class="ts-fs-7 ts-text-gray-1a fw-medium mb-1">
                    {{
                      $store.state.creatorSignupPage.mainDetails
                        .TestimonialContact.title
                    }}
                  </h5>
                  <div
                    class="ts-social-links d-flex gap-2 gap-sm-3 justify-content-start"
                  >
                    <a
                      class="ts-text-gray-4"
                      :href="
                        $store.state.creatorSignupPage.mainDetails
                          .TestimonialContact.instagramLink
                      "
                      ><i class="bi bi-instagram"></i></a
                    ><a
                      class="ts-text-gray-4"
                      :href="
                        $store.state.creatorSignupPage.mainDetails
                          .TestimonialContact.twitchLink
                      "
                      ><i class="bi bi-twitch"></i></a
                    ><a
                      class="ts-text-gray-4"
                      :href="
                        $store.state.creatorSignupPage.mainDetails
                          .TestimonialContact.twitterLink
                      "
                      ><i class="bi bi-twitter-x"></i></a>
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div class="order-0 order-lg-1">
            <div class="ts-contact">
              <div class="ts-contact__content">
                <div class="ts-contact-form-container ts-shadow-1">
                  <div class="mb-3 position-relative">
                    <button
                      class="ts-btn ts-btn--sm border-0 ts-btn--back ts-fs-7-ii position-absolute left-0 top-0 p-1 px-2 rounded-1 mt-1"
                      @click="handleSlideBack"
                      :class="[isLastSlide ? 'd-none' : '']"
                    >
                      <Icon name="chevron-left" />
                    </button>
                    <div
                      class="ts-creator-swiper-wrapper"
                      :class="isLastSlide ? 'd-none' : ''"
                    >
                      <swiper
                        :slidesPerView="1"
                        :spaceBetween="16"
                        :pagination="{
                          clickable: false,
                          el: '.ts-creator-form-swiper',
                        }"
                        :modules="modules"
                        class="ts-creator-swiper mySwiper d-flex"
                        :class="isLastSlide ? 'd-none' : ''"
                        :allowSlidePrev="true"
                        :allowTouchMove="false"
                        :loop="false"
                        ref="tsCreatorSwiper"
                        @swiper="onSwiper"
                        @slideChange="handleSlideChange"
                        v-if="$store.state.creatorSignupPage"
                      >
                        <swiper-slide>
                          <Form1
                            @submit-form="FromSumbitStatus"
                            ref="creatorSignupform1"
                          />
                        </swiper-slide>
                        <swiper-slide>
                          <Form2
                            @submit-form="FromSumbitStatus"
                            ref="creatorSignupform2"
                            :creator-id="signupForm.cretor_id"
                          />
                        </swiper-slide>
                        <swiper-slide class="h-auto">
                          <Form3
                            @submit-form="FromSumbitStatus"
                            @request-status="requestStatus"
                            ref="creatorSignupform3"
                            :creator-id="signupForm.cretor_id"
                          />
                        </swiper-slide>
                        <swiper-slide>
                          <Form4
                            @submit-form="FromSumbitStatus"
                            @add-social-media="addSocialMediaPop"
                            ref="creatorSignupform4"
                            :creator-id="signupForm.cretor_id"
                            :form-four-data="formFourData"
                          />
                        </swiper-slide>
                      </swiper>
                    </div>

                    <div
                      class="ts-congratulation-wrapper"
                      :class="isLastSlide ? '' : 'd-none'"
                      v-if="$store.state.creatorSignupPage"
                    >
                      <Congratulation>
                        <h1
                          class="ts-fs-4-1 ts-text-gray-5-a fw-bold text-center mb-01"
                        >
                          {{
                            $store.state.creatorSignupPage.Congratulation.title
                          }}
                        </h1>
                        <p class="ts-fs-7 ts-text-gray-6-iii fw-light mb-3">
                          {{
                            $store.state.creatorSignupPage.Congratulation
                              .description
                          }}
                        </p>
                      </Congratulation>
                    </div>

                    <div
                      class="ts-creator-form-swiper ts-swiper-pagination-2 d-flex justify-content-center"
                      :class="isLastSlide ? 'invisible' : ''"
                    ></div>
                  </div>

                  <div v-if="$store.state.creatorSignupPage">
                    <button
                      v-if="!processingStatus"
                      class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill mt-auto text-center"
                      @click="handleFormSubmit"
                      :class="[
                        btnText === 'Continue'
                          ? 'ts-btn-primary'
                          : 'ts-btn-secondary',
                        isLastSlide ? 'd-none' : '',
                      ]"
                    >
                      {{ btnText }}
                    </button>
                    <button
                      v-else
                      class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-100 rounded-pill mt-auto"
                      :class="[
                        btnText ===
                        $store.state.creatorSignupPage.FormActions
                          .ContinueBtnText
                          ? 'ts-btn-primary'
                          : 'ts-btn-secondary',
                        isLastSlide ? 'd-none' : '',
                      ]"
                    >
                      <i
                        class="spinner-border spinner-border-sm text-light"
                        role="status"
                      ></i>
                      {{ btnText }}
                    </button>
                    <button
                      class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-secondary w-100 rounded-pill"
                      @click="handleCongratulations"
                      :class="isLastSlide ? '' : 'd-none'"
                    >
                      {{
                        $store.state.creatorSignupPage.FormActions.DoneBtnText
                      }}
                    </button>
                  </div>
                  <h3
                    class="ts-fs-7-ii text-center ts-already-signup fw-normal mb-0"
                    :class="isLastSlide ? 'invisible' : ''"
                    v-if="$store.state.creatorSignupPage"
                  >
                    {{
                      $store.state.creatorSignupPage.PageLinks
                        .alreadyHaveAnAccount.text
                    }}

                    <!-- <router-link
                      class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                      :to="
                        $store.state.creatorSignupPage.PageLinks
                          .alreadyHaveAnAccount.linkTo
                      "
                    >
                      {{
                        $store.state.creatorSignupPage.PageLinks
                          .alreadyHaveAnAccount.linkText
                      }}
                    </router-link> -->
                    <CustomLinkLogin
                      class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                      :text="
                        $store.state.creatorSignupPage.PageLinks
                          .alreadyHaveAnAccount.linkText
                      "
                      :toSrc="
                        $store.state.creatorSignupPage.PageLinks
                          .alreadyHaveAnAccount.linkTo
                      "
                    />
                  </h3>
                </div>
                <h3
                  class="ts-fs-7-ii text-center ts-already-signup-2 fw-normal"
                  v-if="$store.state.creatorSignupPage"
                >
                  {{
                    $store.state.creatorSignupPage.PageLinks.areYouABrand.text
                  }}

                  <router-link
                    class="fw-bold ts-text-primary-1 text-decoration-none fw-bold"
                    :to="
                      $store.state.creatorSignupPage.PageLinks.areYouABrand
                        .linkTo
                    "
                  >
                    {{
                      $store.state.creatorSignupPage.PageLinks.areYouABrand
                        .linkText
                    }}
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#addSocialMediaConfirmInsta"
    >
      Launch demo modal
    </button> -->
  </section>

  <!-- Modals Insta -->
  <!-- Modal -->
  <div
    class="modal fade"
    id="addSocialMedia"
    tabindex="-1"
    aria-labelledby="addSocialMediaInstaLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content ts-add-social-media-modal-content">
        <div class="modal-body p-0" v-if="$store.state.creatorSignupPage">
          <div
            class="ts-social-media-handle-group mb-5"
            data-bs-toggle="modal"
            data-bs-target="#addSocialMediaInsta"
          >
            <div class="ts-social-media-handle ts-social-media-handle--lg">
              <Icon :name="addSocialMediaData.icon" />
            </div>
            <p class="ts-fs-7 mb-0">{{ addSocialMediaData.platForm }}</p>
          </div>
          <form
            class="ts-contact"
            v-if="addSocialMediaData.isAvailable"
            key="popupDataForAvailableSocialMediaPlatform"
          >
            <div class="mb-3">
              <input
                type="text"
                class="form-control form-control-lg rounded-pill"
                id="username"
                :placeholder="
                  $store.state.creatorSignupPage.socialVarification.userName
                "
                v-model="addSocialMediaData.username"
                :class="{ 'is-invalid': addSocialMediaData.hasError }"
              />
              <div class="invalid-feedback">
                {{ addSocialMediaData.errorMessage }}
              </div>
            </div>
          </form>
          <div
            class="ts-contact"
            v-else
            key="popupDataForNotAvailableSocialMediaPlatform"
          >
            <div class="mb-5" style="text-align: center">
              <p>
                {{
                  $store.state.creatorSignupPage.socialVarification.commingSoon
                }}
              </p>
            </div>
          </div>
          <button
            class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-50 rounded-pill"
            data-bs-dismiss="modal"
            :class="{ 'w-100': !addSocialMediaData.isAvailable }"
          >
            {{
              addSocialMediaData.isAvailable
                ? $store.state.creatorSignupPage.socialVarification.cancel
                : $store.state.creatorSignupPage.socialVarification.ok
            }}
          </button>
          <button
            v-if="addSocialMediaData.isAvailable && socialMediaCheckProgress"
            key="ContinueForAvailableSocialMediaPlatform"
            class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-50 rounded-pill"
          >
            <i
              class="spinner-border spinner-border-sm text-light"
              role="status"
            ></i>
          </button>
          <button
            v-if="addSocialMediaData.isAvailable && !socialMediaCheckProgress"
            key="ContinueForAvailableSocialMediaPlatform"
            class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-50 rounded-pill"
            @click="checkInstaTiktokExists(addSocialMediaData.username)"
          >
            {{ $store.state.creatorSignupPage.socialVarification.continue }}
          </button>
          <button
            id="toggleSocialmediaModalInsta"
            data-bs-toggle="modal"
            data-bs-target="#addSocialMediaConfirm"
            style="display: none"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <!-- Change only component value  ConfirmModelInsta =>  ConfirmModelTiktok  dynamically-->
  <div
    class="modal fade"
    id="addSocialMediaConfirm"
    tabindex="-2"
    aria-labelledby="addSocialMediaConfirmInstaLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content ts-add-social-media-modal-content">
        <div class="modal-body p-0" v-if="$store.state.creatorSignupPage">
          <div class="d-flex justify-content-center align-item-center mb-06">
            <div class="ts-copy-code d-flex">
              <p class="ts-text-gray-7 fw-semibold mb-0 lh-1">
                {{ signupForm.instagramPIN }}
              </p>
              <button class="btn border-0 p-0 lh-1" @click="copyInstaCode">
                <Icon name="copy" />
              </button>
              <input
                type="hidden"
                id="instaCodeCopy"
                :value="signupForm.instagramPIN"
              />
            </div>
          </div>

          <VueMarkdown
            class="ts-markdown text-center mb-07"
            :source="
              $store.state.creatorSignupPage[
                this.addSocialMediaData.localizationGroup
              ].description
            "
          />
          <a
            class="ts-btn ts-btn--sm ts-btn-secondary w-100 rounded-pill mb-3 text-center"
            :href="
              $store.state.creatorSignupPage[
                this.addSocialMediaData.localizationGroup
              ].socialMediaBtn.to
            "
            target="_blank"
          >
            {{
              $store.state.creatorSignupPage[
                this.addSocialMediaData.localizationGroup
              ].socialMediaBtn.text
            }}
          </a>
          <button
            class="ts-btn ts-btn--sm ts-btn-primary w-100 rounded-pill"
            data-bs-dismiss="modal"
          >
            {{
              $store.state.creatorSignupPage[
                this.addSocialMediaData.localizationGroup
              ].doneButtonText
            }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modals Tiktok -->
  <!-- <div
    class="modal fade"
    id="addSocialMediaTikTok"
    tabindex="-1"
    aria-labelledby="addSocialMediaTikTokLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content ts-add-social-media-modal-content">
        <div class="modal-body p-0">
          <div
            class="ts-social-media-handle-group mb-5"
            data-bs-toggle="modal"
            data-bs-target="#addSocialMediaTikTok"
          >
            <div class="ts-social-media-handle ts-social-media-handle--lg">
              <Icon :name="addSocialMediaData.icon" />
            </div>
            <p class="ts-fs-7 mb-0">{{ addSocialMediaData.platForm }}</p>
          </div>
          <form
            class="ts-contact"
            v-if="addSocialMediaData.isAvailable"
            key="popupDataForAvailableSocialMediaPlatform"
          >
            <div class="mb-3">
              <input
                type="text"
                class="form-control form-control-lg rounded-pill"
                id="username"
                placeholder="@username"
                v-model="addSocialMediaData.username"
                :class="{ 'is-invalid': addSocialMediaData.hasError }"
              />
              <div class="invalid-feedback">
                {{ addSocialMediaData.errorMessage }}
              </div>
            </div>
          </form>
          <div
            class="ts-contact"
            v-else
            key="popupDataForNotAvailableSocialMediaPlatform"
          >
            <div class="mb-5" style="text-align: center">
              <p>Comming soon</p>
            </div>
          </div>
          <button
            class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-50 rounded-pill"
            data-bs-dismiss="modal"
            :class="{ 'w-100': !addSocialMediaData.isAvailable }"
          >
            {{ addSocialMediaData.isAvailable ? "Cancel" : "OK" }}
          </button>
          <button
            v-if="addSocialMediaData.isAvailable"
            key="ContinueForAvailableSocialMediaPlatform"
            class="ts-btn ts-btn--sm ts-fs-7-ii ts-btn-primary w-50 rounded-pill"
            @click="addSocialMediaId"
          >
            Continue
          </button>
          <button
            id="toggleSocialmediaModalTikTok"
            data-bs-toggle="modal"
            data-bs-target="#addSocialMediaConfirm"
            style="display: none"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="addSocialMediaConfirmTikTok"
    tabindex="-2"
    aria-labelledby="addSocialMediaConfirmTikTokLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered justify-content-center">
      <div class="modal-content ts-add-social-media-modal-content">
        <div class="modal-body p-0" v-if="$store.state.creatorSignupPage">
          <div class="d-flex justify-content-center align-item-center mb-06">
            <div class="ts-copy-code d-flex">
              <p class="ts-text-gray-7 fw-semibold mb-0 lh-1">
                {{ signupForm.instagramPIN }}
              </p>
              <button class="btn border-0 p-0 lh-1" @click="copyInstaCode">
                <Icon name="copy" />
              </button>
              <input
                type="hidden"
                id="instaCodeCopy"
                :value="signupForm.instagramPIN"
              />
            </div>
          </div>

          <VueMarkdown
            class="ts-markdown text-center mb-07"
            :source="
              $store.state.creatorSignupPage.ConfirmModelTiktok.description
            "
          />
          <a
            class="ts-btn ts-btn--sm ts-btn-secondary w-100 rounded-pill mb-3 text-center"
            :href="
              $store.state.creatorSignupPage.ConfirmModelTiktok.socialMediaBtn
                .to
            "
            target="_blank"
          >
            {{
              $store.state.creatorSignupPage.ConfirmModelTiktok.socialMediaBtn
                .text
            }}
          </a>
          <button
            class="ts-btn ts-btn--sm ts-btn-primary w-100 rounded-pill"
            data-bs-dismiss="modal"
          >
            {{
              $store.state.creatorSignupPage.ConfirmModelTiktok.doneButtonText
            }}
          </button>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import { ref } from "vue";
import Icon from "../../assets/icons/index.vue";
import router from "@/router";
import { axiosAPI } from "../../axiosClient";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import CustomLinkLogin from "../Helpers/CustomLinkLogin.vue";
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

import Form1 from "./creator/Form1.vue";
import Form2 from "./creator/Form2.vue";
import Form3 from "./creator/Form3.vue";
import Form4 from "./creator/Form4.vue";
import Congratulation from "./common/Congratulation.vue";
import { toast } from "vue3-toastify";
import VueMarkdown from "markdown-vue";
const formStatus = ref(false);
const processingStatus = ref(false);
const socialMediaCheckProgress = ref(false);
export default {
  name: "CreatorSignupHero",
  components: {
    Form1,
    Form2,
    Form3,
    Form4,
    Congratulation,
    Swiper,
    SwiperSlide,
    Icon,
    VueMarkdown,
    CustomLinkLogin,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
    markdownRules() {
      return {
        link_open(tokens, idx, options, env, self) {
          const aToken = tokens[idx];
          aToken.attrPush(["target", "_blank"]);
          return self.renderToken(tokens, idx, options);
        },
      };
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    "addSocialMediaData.username"() {
      if (this.addSocialMediaData.hasError) {
        this.addSocialMediaData.hasError = false;
      }
    },
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getErrorMessages");
      }
    },
  },

  data() {
    return {
      processingStatus: processingStatus,
      socialMediaCheckProgress: socialMediaCheckProgress,
      btnText: "Continue",
      isLastSlide: false,
      isLastSlideActive: false,
      elementVisible: false,
      signupForm: {
        cretor_id: "",
        instagramPIN: "",
      },
      formFourData: {
        instagram_url: "",
        tiktok: "",
      },
      addSocialMediaData: {
        isAvailable: true,
        username: "",
        icon: "",
        platForm: "",
        type: "",
        hasError: false,
        errorMessage: "",
        localizationGroup: "ConfirmModelInsta",
      },
      requestTimeout: "",
    };
  },
  created() {
    setTimeout(() => (this.elementVisible = true), 500);
  },
  beforeCreate() {
    this.$store.dispatch("getNationalities");
    this.$store.dispatch("getCountries");
    this.$store.dispatch("getErrorMessages");
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    handleSlideChange() {
      this.isLastSlideActive = false;
      this.btnText = "Continue";
      if (this.swiper.isEnd || this.swiper.activeIndex === 3) {
        this.btnText = "Done";
      }
    },
    handleFormSubmit() {
      switch (this.swiper.realIndex) {
        case 0:
          processingStatus.value = true;
          formStatus.value = false;
          this.$refs.creatorSignupform1.submitFrom1();
          // formStatus.value = true;
          // this.handleSlideNext();
          break;
        case 1:
          processingStatus.value = true;
          formStatus.value = false;
          this.$refs.creatorSignupform2.submitFrom2();
          // formStatus.value = true;
          // this.handleSlideNext();
          break;
        case 2:
          processingStatus.value = true;
          formStatus.value = false;
          this.$refs.creatorSignupform3.verifyPin();
          // formStatus.value = true;
          // this.handleSlideNext();
          break;
        case 3:
          processingStatus.value = true;
          formStatus.value = false;
          this.$refs.creatorSignupform4.submitFrom4();
          break;
        default:
          break;
      }
    },
    handleSlideNext() {
      if (formStatus.value) {
        this.isLastSlide = false;
        this.swiper.slideNext();
        this.btnText = "Continue";

        if (this.isLastSlideActive) {
          this.isLastSlide = true;
        }
        if (this.swiper.isEnd) {
          this.btnText = "Done";
          this.isLastSlideActive = true;
        }
      }
    },
    handleSlideBack(e) {
      if (!processingStatus.value) {
        this.isLastSlide = false;
        this.swiper.slidePrev();

        // if (this.isLastSlideActive) {
        //   this.isLastSlide = true;
        // }
        if (this.swiper.isBeginning) {
          e.target.disabled;
        }
      }
    },
    handleCongratulations() {
      router.push({ path: "/" });
      // this.swiper.slideTo(0);
      // this.isLastSlide = false;
    },
    FromSumbitStatus(i) {
      formStatus.value = i.status;
      processingStatus.value = false;
      if (i.status) {
        this.signupForm.cretor_id = i.creator_id;
      }
      if (i.instagramPin) {
        this.signupForm.instagramPIN = i.instagramPin;
      }
      if (i.redirect) {
        router.push({ path: "/" });
      } else {
        this.handleSlideNext();
      }
    },
    requestStatus(i) {
      formStatus.value = i.status;
    },
    addSocialMediaPop(platform) {
      this.addSocialMediaData.platForm =
        platform.charAt(0).toUpperCase() + platform.slice(1);
      this.addSocialMediaData.type = platform;
      this.addSocialMediaData.hasError = false;
      this.addSocialMediaData.errorMessage = "";
      switch (platform) {
        case "instagram":
          this.addSocialMediaData.isAvailable = true;
          this.addSocialMediaData.icon = "instagram-lg";
          this.addSocialMediaData.username = this.formFourData.instagram_url;
          this.addSocialMediaData.localizationGroup = "ConfirmModelInsta";
          break;
        case "tiktok":
          this.addSocialMediaData.isAvailable = true;
          this.addSocialMediaData.icon = "tiktok";
          this.addSocialMediaData.username = this.formFourData.tiktok;
          this.addSocialMediaData.localizationGroup = "ConfirmModelTiktok";
          break;
        case "snapchat":
          this.addSocialMediaData.isAvailable = false;
          this.addSocialMediaData.icon = "snapchat";
          break;
        case "twitch":
          this.addSocialMediaData.isAvailable = false;
          this.addSocialMediaData.icon = "twitch";
          break;
        case "youtube":
          this.addSocialMediaData.isAvailable = false;
          this.addSocialMediaData.icon = "youtube";
          break;
        case "twitter":
          this.addSocialMediaData.isAvailable = false;
          this.addSocialMediaData.icon = "twitter";
          break;

        default:
          this.addSocialMediaData.isAvailable = false;
          break;
      }
    },
    addSocialMediaId() {
      switch (this.addSocialMediaData.type) {
        case "instagram":
          this.formFourData.instagram_url = this.addSocialMediaData.username;
          document.getElementById("toggleSocialmediaModalInsta").click();
          break;
        case "tiktok":
          this.formFourData.tiktok = this.addSocialMediaData.username;
          document.getElementById("toggleSocialmediaModalInsta").click();
          break;

        default:
          this.addSocialMediaData.isAvailable = false;
          break;
      }
      socialMediaCheckProgress.value = false;
    },
    copyInstaCode() {
      let instaCodeToCopy = document.querySelector("#instaCodeCopy");
      instaCodeToCopy.setAttribute("type", "text");
      instaCodeToCopy.select();
      try {
        var successful = document.execCommand("copy");
        if (this.$store.state.errorMessges) {
          if (successful) {
            toast.success(this.$store.state.errorMessges.code_copy_successful);
          } else {
            toast.success(
              this.$store.state.errorMessges.code_copy_unsuccessful
            );
          }
        }
      } catch (err) {
        if (this.$store.state.errorMessges) {
          toast.error(this.$store.state.errorMessges.code_copy_unsuccessful);
        }
      }

      /* unselect the range */
      instaCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    checkInstaTiktokExists(data) {
      if (this.addSocialMediaData.username.trim() != "") {
        if (!socialMediaCheckProgress.value) {
          socialMediaCheckProgress.value = true;
          let FormData = {};
          if (this.addSocialMediaData.type == "instagram") {
            FormData["instagram_url"] = data;
            FormData["tiktok"] = "";
          } else if (this.addSocialMediaData.type == "tiktok") {
            FormData["tiktok"] = data;
            FormData["instagram_url"] = "";
          }
          let _this = this;
          axiosAPI
            .post(`v1/creator/check-instaticktok`, FormData)
            .then((response) => {
              if (response.data && response.data.status) {
                this.addSocialMediaData.hasError = false;
                this.addSocialMediaData.errorMessage = "";
                this.addSocialMediaId();
              } else {
                socialMediaCheckProgress.value = false;
                this.addSocialMediaData.hasError = true;
                if (this.$store.state.errorMessges) {
                  toast.error(
                    this.$store.state.errorMessges[response.data.msg_slug]
                  );
                }
                this.addSocialMediaData.errorMessage =
                  this.$store.state.errorMessges[response.data.msg_slug];
              }
            })
            .catch(function (error) {
              socialMediaCheckProgress.value = false;
              _this.addSocialMediaData.hasError = true;
              if (error.response && error.response.data) {
                for (const error_msg in error.response.data) {
                  _this.addSocialMediaData.errorMessage =
                    error.response.data[error_msg];
                }
              }
            });
        }
      } else {
        this.addSocialMediaData.hasError = true;
        socialMediaCheckProgress.value = false;
      }
    },
  },

  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
};
</script>
<style lang="scss" scoped>
.ts-btn--back {
  z-index: 20;
  background-color: transparent;
}

// .ts-creator-swiper {
//   min-height: 433px;
// }
.ts-congratulation {
  min-height: 384px;
  max-width: 393px;
  margin: auto;

  @media (min-width: 992px) {
    min-height: 442px;
  }
}

.ts-bg-gradient {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -7;

  @media (max-width: 1500px) {
    overflow: hidden;
  }
}

.ts-social-links {
  font-size: clamp(21px, 3.5vw, 23px);

  svg {
    width: 20px;
  }
}

.ts-social-media-handle {
  border-radius: 1rem;

  &--lg {
    width: 96px;
    height: 96px;
  }

  #instagram {
    width: 55px !important;
    height: 55px !important;
  }
}

.ts-copy-code {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  padding: 11px 25px 11px 35px;
  border-radius: 8px;
  gap: 1rem;
}

.ts-add-social-media-modal-content {
  max-width: 405px;
  padding: 57px 36px;
  border-radius: 34px;
}

@media (max-width: 475px) {
  .ts-creator-form-hero__header {
    max-width: 338px;
    margin: auto;
  }
}

.ts-form-hero {
  // padding-block: 14rem 6rem;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  isolation: isolate;
  padding-block: 6.5625rem 3.5rem;
  overflow: hidden;
  min-height: 100vh;

  @media (min-width: 992px) {
    padding-block: 11rem 6rem;
  }

  &__content {
    max-width: 1224px;
    margin: auto;

    @media (min-width: 992px) {
      position: relative;
    }
  }

  .ts-bg-1 {
    position: absolute;
    bottom: 30%;
    left: 0;
    transform: translate(-26%, -119%);
    width: clamp(800px, 140vw, 1302px) !important;
    z-index: -3;

    @media (max-width: 991px) {
      transform: translate(-38%, 17%);
    }
  }

  .ts-bg-2 {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-62%, 61%);
    width: clamp(800px, 120vw, 1060px) !important;
    z-index: -3;

    @media (max-width: 991px) {
      transform: translate(-79%, 61%);
    }
  }

  .ts-bg-3 {
    position: absolute;
    bottom: 30%;
    left: 0;
    transform: translate(-40%, -64%);
    width: clamp(800px, 140vw, 1302px) !important;
    z-index: -2;

    @media (max-width: 991px) {
      transform: translate(-38%, 17%);
    }
  }

  .ts-hash {
    position: absolute;
    left: 0%;
    top: 12%;
    width: clamp(320px, 40vw, 500px);
    z-index: -2;
    transform: translateX(-50%);
    opacity: 85%;

    @media (max-width: 991px) {
      top: auto;
      bottom: -29px;
      left: 3%;
      opacity: 70%;
    }
  }
}

.ts-contact {
  margin: 0 !important;
}

.ts-image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;

  @media (max-width: 991px) {
    overflow: hidden;
  }
}

.ts-image {
  img {
    position: absolute !important;
  }

  &__1 {
    img {
      width: clamp(80px, 8vw, 120px) !important;
      animation: floating 2.5s ease-in-out infinite;
    }
  }

  &__2 {
    img {
      width: clamp(90px, 12vw, 140px) !important;
      animation: floating 3s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(30deg);
    }
  }

  &__3 {
    img {
      width: clamp(80px, 10vw, 120px) !important;
      animation: floating 3.5s ease-in-out infinite;
    }
  }

  &__4 {
    img {
      width: clamp(80px, 11vw, 110px) !important;
      animation: floating 4s ease-in-out infinite;
    }
  }

  &__5 {
    img {
      width: clamp(80px, 12vw, 140px) !important;
      animation: floating 4s ease-in-out infinite;
    }

    .ts-rotate {
      transform: rotate(-30deg);
    }
  }

  &__1 {
    img {
      left: 43%;
      top: 10%;
    }
  }

  &__2 {
    img {
      top: 87%;
      right: 0;

      @media (min-width: 1300px) {
        top: 82%;
        right: -7%;
      }
    }
  }

  &__3 {
    img {
      top: -7%;
      right: 0;

      @media (min-width: 1300px) {
        top: 4%;
        right: -6%;
      }
    }
  }

  &__4 {
    img {
      right: 52% !important;
      top: 72%;
      transform: translate(100%, -100%);
    }
  }

  &__5 {
    img {
      top: 100%;
      left: 0%;
      transform: translate(-260%, 181%) !important;
    }
  }

  @media (max-width: 1400px) and (min-width: 1201px) {
    &__5 {
      img {
        transform: translate(-193%, 188%) !important;
      }
    }
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    &__5 {
      img {
        transform: translate(-206%, 210%) !important;
      }
    }
  }

  @media (max-width: 991px) {
    &__2,
    &__3,
    &__4 {
      display: none;
    }

    &__1 {
      img {
        left: auto !important;
        top: 66%;
        right: 16px;
        transform: rotate(45deg) !important;
      }
    }

    &__5 {
      position: absolute;
      top: 87%;
      left: auto;
      right: 61px;

      .ts-rotate {
        transform: rotate(45deg);
      }

      img {
        left: 80%;
        top: 65%;
        transform: translate(0, 0) !important;
      }
    }
  }
}

@keyframes floating {
  0% {
    transform: rotate(-5deg) translatey(0px);
  }

  50% {
    transform: rotate(-3deg) translatey(7px);
  }

  100% {
    transform: rotate(-5deg) translatey(0px);
  }
}
</style>
